<template>
  <div class="charts col-12 col-lg-11">
    <div
      class="
        col-12
        chartContainer
        dashboard-item-container
        shadow-card
        radius-36
        theme-card
      "
      v-if="$can({ key: 'maxHoursReport', expectedPermission: 'view' })"
    >
      <div class="row m-0 justify-content-between dashboard-item-header">
        <div class="dashboard-item-title">
          {{ selectedPeriod }} Budget by Service
        </div>
        <div class="mb-5 row col-12 col-md-auto pr-0 pl-3 pl-md-0">
          <div class="col-12 col-md-6 px-0">
            <select-control
              v-model="selectedMonthYear.budgetChart"
              :options="lastMonths"
              :key="selectedMonthYear.budgetChart"
              labelClass="d-none"
              :disabled="chartLoading.budgetChart"
              @input="getBudgetChart(true)"
              wrapClass="my-md-0 my-3 mx-0"
            ></select-control>
          </div>
          <div class="col-12 col-md-6 px-0 pl-md-3">
            <select-control
              v-model="selectedPeriod"
              :options="periodOptions"
              :key="selectedPeriod"
              labelClass="d-none"
              :disabled="chartLoading.budgetChart"
              @input="getBudgetChart(true)"
              wrapClass="m-0"
            ></select-control>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <div class="row m-0 justify-content-between mb-3">
          <div class="h4 m-0 d-flex align-items-center">Budget in $</div>
          <button
            class="btn btn-sm btn-black pb-0 ml-2"
            :disabled="chartLoading.budgetChart"
            @click="
              !chartLoading.budgetChart && changeChartType('moneyBudgetChart')
            "
          >
            {{ chartHorizontal.moneyBudgetChart ? "Horizontal" : "Vertical" }}
            <i
              :class="`ml-2 fas ${
                chartHorizontal.moneyBudgetChart
                  ? 'fa-grip-horizontal'
                  : 'fa-grip-vertical'
              }`"
            ></i>
          </button>
        </div>

        <alert
          v-if="
            !chartsData.moneyBudgetChart.length && !chartLoading.budgetChart
          "
          :hideLoader="true"
          >No data to show</alert
        >
        <alert v-if="chartLoading.budgetChart" />
        <div id="moneyBudgetChart" v-show="!noChartData.moneyBudgetChart"></div>
      </div>
      <hr />
      <div>
        <div class="row m-0 justify-content-between mb-3">
          <div class="h4 m-0 d-flex align-items-center">Budget in Hours</div>
          <button
            class="btn btn-sm btn-black pb-0 ml-2"
            :disabled="chartLoading.budgetChart"
            @click="
              !chartLoading.budgetChart && changeChartType('hoursBudgetChart')
            "
          >
            {{ chartHorizontal.hoursBudgetChart ? "Horizontal" : "Vertical" }}
            <i
              :class="`ml-2 fas ${
                chartHorizontal.hoursBudgetChart
                  ? 'fa-grip-horizontal'
                  : 'fa-grip-vertical'
              }`"
            ></i>
          </button>
        </div>
        <alert
          v-if="
            !chartsData.hoursBudgetChart.length && !chartLoading.budgetChart
          "
          :hideLoader="true"
          >No data to show</alert
        >
        <alert v-if="chartLoading.budgetChart" />
        <div id="hoursBudgetChart" v-show="!noChartData.hoursBudgetChart"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import dateFormat from "@/utils/dateFormat";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ApexCharts from "apexcharts";
import CONSTANT from "./constants";
dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  data() {
    return {
      showCharts: false,
      chartsRef: {
        hoursBudgetChart: null,
        moneyBudgetChart: null,
      },
      chartHorizontal: {
        hoursBudgetChart: false,
        moneyBudgetChart: false,
      },
      noChartData: {
        hoursBudgetChart: false,
        moneyBudgetChart: false,
      },
      chartsData: {
        hoursBudgetChart: [],
        moneyBudgetChart: [],
      },
      selectedPeriod: "Weekly",
      periodOptions: [
        { value: "Daily", label: "Daily" },
        { value: "Weekly", label: "Weekly" },
        { value: "Monthly", label: "Monthly" },
      ],
      chartLoading: {
        budgetChart: true,
      },
      currentYear: new Date().getFullYear(),
      lastMonths: [],
      hoursBudgetChart: CONSTANT.getBudgetBarChart(),
      moneyBudgetChart: CONSTANT.getBudgetBarChart(),
      hoursBudgetChartHorizontal: CONSTANT.getBudgetBarChartHorizontal(),
      moneyBudgetChartHorizontal: CONSTANT.getBudgetBarChartHorizontal(),
      selectedMonthYear: {
        budgetChart: `${new Date().getFullYear()}__${new Date().getMonth()}`,
      },
    };
  },
  mounted() {
    this.setYearsMonths();
    this.getBudgetChart();
  },
  beforeDestroy() {
    this.chartsRef.hoursBudgetChart &&
      this.chartsRef.hoursBudgetChart.destroy();
    this.chartsRef.moneyBudgetChart &&
      this.chartsRef.moneyBudgetChart.destroy();
  },
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
      budgetChartData: (state) => state.patients.maxhours.budgetChartData,
    }),
  },
  methods: {
    ...mapActions({
      getBudgetChartByPeriod: "patients/maxhours/getBudgetChartByPeriod",
    }),
    getBudgetChart: function (monthChanged) {
      const vm = this;
      if (monthChanged) {
        this.resetChart("money");
        this.resetChart("hours");
      }

      const selectedYear = this.selectedMonthYear.budgetChart.split("__")[0];
      const selectedMonth =
        parseInt(this.selectedMonthYear.budgetChart.split("__")[1]) + 1;

      const adjustedTimePeriod = this.adjustTimePeriod(
        selectedYear,
        selectedMonth
      );

      this.getBudgetChartByPeriod({
        id: vm.patient.user_id,
        period_type: this.selectedPeriod,
        ...adjustedTimePeriod,
      }).then(() => {
        vm.chartsData.moneyBudgetChart = vm.getBudgetChartDataFor(
          this.selectedPeriod,
          "money"
        );
        vm.chartsData.hoursBudgetChart = vm.getBudgetChartDataFor(
          this.selectedPeriod,
          "hours"
        );
        if (vm.chartsData.moneyBudgetChart.length == 0) {
          this.noChartData.moneyBudgetChart = true;
        }
        if (vm.chartsData.hoursBudgetChart.length == 0) {
          this.noChartData.hoursBudgetChart = true;
        }
        vm.initiateChart(
          vm.chartsData.hoursBudgetChart,
          "hoursBudgetChart",
          "Hours"
        );
        vm.initiateChart(
          vm.chartsData.moneyBudgetChart,
          "moneyBudgetChart",
          "Money"
        );
      });
    },
    getBudgetChartDataFor: function (period_type, type) {
      let data = this.budgetChartData;
      if (type == "money") {
        data = data.filter((item) => {
          return item.budget_value;
        });
      }
      return this.getChartRecordsFor(type, data, period_type);
    },
    getChartRecordsFor: function (type, budgetData, period_type) {
      return budgetData.map((item) => {
        const chartRecord = {
          x: item.service_name,
          y: parseFloat(item[`${type}_actual_budget`]),
          z:
            type == "hours"
              ? `${+parseFloat(item.max_hour_value).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                ) + `H ${period_type}`
              : `$${+parseFloat(item.budget_value).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                ) + ` ${period_type}`,
          goals: [
            {
              name: `Budget in ${type == "money" ? "$" : "Hours"}: `,
              value: parseFloat(item[`${type}_defined_budget`]),
              strokeColor: "#d7263d",
            },
          ],
          t: type,
        };
        return chartRecord;
      });
    },
    changeChartType: function (chartSelector) {
      if (document.querySelector(`#${chartSelector}`)) {
        this.chartLoading[chartSelector] = true;
        this.chartsRef[chartSelector].destroy();
        setTimeout(() => {
          if (this.chartHorizontal[chartSelector]) {
            this.chartsRef[chartSelector] = new ApexCharts(
              document.querySelector(`#${chartSelector}`),
              this[`${chartSelector}`]
            );
          } else {
            this.chartsRef[chartSelector] = new ApexCharts(
              document.querySelector(`#${chartSelector}`),
              this[`${chartSelector}Horizontal`]
            );
          }

          this.chartsRef[chartSelector].render();
          this.chartLoading[chartSelector] = false;
          this.chartHorizontal[chartSelector] =
            !this.chartHorizontal[chartSelector];
        }, 100);
      }
    },
    initiateChart: function (data, chartName, type) {
      this[`${chartName}Horizontal`].series[0].name = this[
        chartName
      ].series[0].name = `Spending in ${type == "Money" ? "$" : "Hours"}`;

      this[`${chartName}Horizontal`].series[0].data = this[
        chartName
      ].series[0].data = data;
      this[`${chartName}Horizontal`].chart.height =
        data.length < 4 ? 200 : data.length * 50;
      if (document.querySelector(`#${chartName}`)) {
        if (this.chartHorizontal[chartName]) {
          this.chartsRef[chartName] = new ApexCharts(
            document.querySelector(`#${chartName}`),
            this[`${chartName}Horizontal`]
          );
        } else {
          this.chartsRef[chartName] = new ApexCharts(
            document.querySelector(`#${chartName}`),
            this[chartName]
          );
        }
        this.chartLoading.budgetChart = false;
        this.chartsRef[chartName].render();
      }
    },
    adjustTimePeriod: function (year, month) {
      const startOfMonth = `${year}-${
        month > 9 ? month : "0" + month
      }-01T00:00:00.000000Z`;
      const endOfMonth = `${dateFormat(
        dayjs(`${year}-${month > 9 ? month : "0" + month}-01`).endOf("month"),
        "YYYY-MM-DD"
      )}T23:59:59.000000Z`;
      const canadaOffsetStart = dayjs
        .tz(new Date(startOfMonth), "America/Toronto")
        .utcOffset();
      const canadaOffsetEnd = dayjs
        .tz(new Date(endOfMonth), "America/Toronto")
        .utcOffset();

      const startDateTime = dayjs(startOfMonth)
        .add(canadaOffsetStart, "minute")
        .utc()
        .format();

      const endDateTime = dayjs(endOfMonth)
        .add(canadaOffsetEnd, "minute")
        .utc()
        .format();
      return {
        start: startDateTime,
        end: endDateTime,
      };
    },
    resetChart: function (type) {
      const paramMapping = {
        money: "money",
        hours: "hours",
      };
      this.chartLoading.budgetChart = true;
      this.noChartData[`${paramMapping[type]}BudgetChart`] = false;
      if (this.chartsRef[`${paramMapping[type]}BudgetChart`]) {
        this.chartsRef[`${paramMapping[type]}BudgetChart`].destroy();
      }
    },
    setYearsMonths: function () {
      const lastYears = [];
      for (let i = 0; i <= 50; i++) {
        lastYears.push({
          label: this.currentYear - i,
          value: this.currentYear - i,
        });
      }
      this.lastYears = lastYears;

      let year = this.currentYear;
      let month = new Date().getMonth();
      let lastMonths = [];
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      for (let i = 0; i <= 200; i++) {
        lastMonths.push({
          label: `${months[month]} ${year}`,
          value: `${year}__${month--}`,
        });
        if (month < 0) {
          month = 11;
          year--;
        }
      }
      this.lastMonths = lastMonths;
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-item-header {
  select {
    padding-top: 3px;
    padding-bottom: 2px;
    height: 34px;
  }
}
.charts {
  .chartContainer {
    margin-top: 32px;
  }
  .dashboard-item-container {
    padding: 36px 32px;

    @media (max-width: 767px) {
      padding: 32px 12px;
    }
    .dashboard-item-header {
      color: #000000;
      border-bottom: 1px solid #c1c4d0;
      .dashboard-item-title {
        font-size: 28px;
        @media (max-width: 767px) {
          font-size: 22px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.patients-budget-charts-main-container {
  .dashboard-item-container {
    border-radius: 36px !important;
  }
}
</style>
